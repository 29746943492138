<template>
    <div> 
        <IconTopTable />
        <div class="content-table-results mt-2">
            <table class="table table-fixed table-stripes" id="table_Ema">
                <ColumnColGroup page="result" :column="data.column" :default_column="data.default_column" />
                <ColumnThead page="result" :column="data.column" :default_column="data.default_column" />
                <tbody> 
                    <template v-if="data.data.data && data.data.data.length > 0">
                        <tr v-for="(item, key) in data.data.data" :key="key">                
                            <td>
                                <label class="table-check">
                                    <input type="checkbox" v-model="select_export" :value="item.drug_id">
                                    <span class="checkbox-table"></span>
                                </label>
                            </td>
                            <td>
                                <router-link :to="'/detail/'+item.drug_id+'/'+item.agency_model.toLowerCase()" target="_blank" rel="noopener">
                                    <div class="icon-fiche"></div>
                                </router-link>
                                <div v-if="isAdmin()">
                                     <a :href="modifyPage(item.agency_model.toLowerCase(), item.drug_id)" target="_blank" rel="noopener"> 
                                        <em class="icon-edit"></em>
                                    </a>
                                </div>
                            </td>
                            <template v-for="(column) in data.column" :key="column.code">
                                <td v-if="column.Status && column.code == 'nmpa_001'" :code="column.code">
                                    {{item.drug_id}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'nmpa_002'" :code="column.code">
                                    {{check_empty(item.category)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'nmpa_003'" :code="column.code">
                                    {{check_empty(item.dci_new)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'nmpa_004'" :code="column.code">
                                    <span v-html="check_empty(overview_regulatory_status(item.current_ma_status))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'nmpa_005'" :code="column.code">
                                    <span v-html="check_empty(overview_regulatory_status(item))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'nmpa_006'" :code="column.code">
                                    {{check_empty(item.name)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'nmpa_007'" :code="column.code">
                                    {{check_empty(item.dci)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'nmpa_008'" :code="column.code">
                                    {{check_empty(item.firm)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'nmpa_009'" :code="column.code">
                                    {{check_empty(DDMMMYY(item.decision_date_num))}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'nmpa_010'" :code="column.code">                                
                                    {{check_empty_with_locale(item.decision,'decision.')}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'nmpa_012'" :code="column.code">
                                    <div v-if="$i18n.locale === 'fr'" v-html="check_empty(array(item.type_of_ma_fr))"></div>
                                    <div v-else v-html="check_empty(array(item.type_of_ma_en))"></div>
                                </td>
                                <td v-else-if="column.Status && column.code == 'nmpa_016'" :code="column.code">                                
                                    <span v-html="check_empty(array(item['indexation_'+$i18n.locale]))"></span>
                                </td>                    
                                <td v-else-if="column.Status && column.code == 'nmpa_017'" :code="column.code">
                                    <Readmore :longText="item['indication_en']" />
                                </td>                            
                                <td v-else-if="column.Status && column.code == 'nmpa_018'" :code="column.code">
                                    <span v-if="$i18n.locale === 'fr'" v-html="check_empty(array(item.classification_fr))"></span>
                                    <span v-else v-html="check_empty(array(item.classification_en))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'nmpa_020'" :code="column.code">
                                    {{check_empty(item.guidance_id)}}
                                </td>

                            </template>
                        </tr>

                    </template>

                    <template v-else><tr><td colspan="99">{{$t('No Data')}}</td></tr></template>

                </tbody>

            </table>
        </div>
        <ModalEssaisClinique :id="drug_id" agency="nmpa"/>
    </div>
</template>

<script>

import IconTopTable from '@/components/result/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue';
import Readmore from '@/components/elements/readmore.vue';
import { isAdmin, overview_regulatory_status, modifyPage, icon_link, DDMMMYY, check_empty, 
        nct_number, treatment_line, array, check_empty_with_locale } from '@/utils'
import ModalEssaisClinique from '@/components/elements/modal_essais_clinique.vue'
export default {
    name: "nmpa",
    components: {
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        Readmore,
        ModalEssaisClinique
    },
    data(){
        return {
            drug_id : 0 //for essais clinique popup
        }
    },
     computed: {                
        data(){
            return this.$store.getters['result/data'];
        },
        listAgency(){
            return this.$store.getters['result/listAgency'];
        },
        select_export: {
            set(val){ this.$store.dispatch("result/select_export", val) },
            get(){ 
                if(this.$store.getters['result/select_export_checkAll']){
                    return true
                }else{
                    return this.$store.getters['result/select_export'] ? this.$store.getters['result/select_export'].listID : null 
                }
            }
        }
    },
    methods: {
        overview_regulatory_status,
        isAdmin,
        modifyPage,        
        icon_link,
        DDMMMYY,
        check_empty,
        nct_number,
        treatment_line,
        array,
        check_empty_with_locale,
        getResults(page = 1) {
            console.log(page)
        },
    },
}
</script>

<style scoped>

/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 70vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}

</style>